import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import WhatWeDoNav from '../../components/WhatWeDoNav'
import Faq from '../../components/Faq'
import Layout from '../../components/Layout'

import FeatureWithImage from '../../components/FeatureWithImage'
import DesignSystemImage from '../../img/pages/what-we-do/ui-design-system.png'
import InformationArchitectureImage from '../../img/pages/what-we-do/ui-information-architecture.png'
import UserInterfaceDevelopmentImage from '../../img/pages/what-we-do/ux-ui-dev.png'
import UserExperienceDevelopmentImage from '../../img/pages/what-we-do/ui-ux-dev.png'
import UniqueDesignImage from '../../img/pages/what-we-do/ui-unique-design.png'

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="what-we-do">
        <Helmet
          title="User Experience & Design | Bishop Software"
          meta={[
            { name: 'description', content: 'Our skilled and creative user experience and interface developer team has 5+ years of experience in web app development. Let\'s talk.' },
            { name: 'keywords', content: 'user,experience,ux,ui,interface,software,development,web,design,mobile,consultancy,consulting,website,application' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#7ac99a 0px, #389583 100%)' section="what-we-do" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: '#FFCB9B', fontSize: '40px', fontWeight: 300}}>User Experience &amp; Design</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Leave no user interaction to chance.</h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>
            </div>
          </Container>
        </section>
      <Scroll.Element name="contact" />

        <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #ddd 0, #fff 30%, #fff 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col md="8" xs="12">
            <p style={{color: '#555', fontSize: '22px'}}>Bishop Software has always aspired to create simple, user-friendly, intuitive solutions for its customers. That’s why, the improvement of user experience is one of the most important tasks for our UX and UI development team when creating apps.</p>
            <p style={{color: '#555', fontSize: '18px'}}>Our skilled and creative UX and UI developers team has 5+ years of proven experience in <Link to="/web-mobile-development" style={{color: '#288d91', textDecoration: 'underline'}}>web app development</Link>. We definitely know how end-users interact with apps and how to create an easy-to-use user interface that is an enjoyable experience for your target audience.</p>
          </Col>
          <Col md="4" xs="12" className="d-none d-md-block">

            <WhatWeDoNav title="Related Services" titleBackground="#dbe5d0" titleColor="#333" color="#288D91" links={[
              { link: '/custom-software-development', title: 'Custom Software Development'},
              { link: '/web-mobile-development', title: 'Web & Mobile Development'},
              { link: '/software-consulting', title: 'Software Consulting'}
            ]} />

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fefefe 80%, #eee 100%)'}}>
      <Container>

        <FeatureWithImage title="Design System" imageSrc={DesignSystemImage}>
        We have created a robust process of UX/UI development through ensuring design and code consistency and a sophisticated product development process. Pieces of the design system are reusable across multiple parts of a product and even across different projects.
        </FeatureWithImage>
        <FeatureWithImage title="Information Architecture" imageSrc={InformationArchitectureImage} right={true}>
        We choose the best way to organize and structure the content and data of your web and mobile apps. We deeply analyze your users’ behavior and actions, as well as their incentives, to create an appropriate solution that will match your core business goals.
        </FeatureWithImage>
        <FeatureWithImage title="User Interface Development" imageSrc={UserInterfaceDevelopmentImage}>
        We will deliver our best efforts to ingeniously turn your ideas and thoughts into a clear and fully functional design. We are not only responsible for designing the architecture of the application but also for the convenient end-user interface of the future solution. 
        </FeatureWithImage>
        <FeatureWithImage title="User Experience Development" imageSrc={UserExperienceDevelopmentImage} right={true}>
        We consider all aspects and possibilities of how an end-user will interact with the future app. To achieve your target goals, we extensively analyze both the users' behavior and the core features of the system to allow the accurate rendering of all visual and functional elements. 
        </FeatureWithImage>
        <FeatureWithImage title="Unique Design" imageSrc={UniqueDesignImage}>
        We create outstanding individual solutions for any business. Our skilled UX/UI development team can design an amazing UI for your future app that definitely will help to differentiate your business solution from others and increase the target audience.
        </FeatureWithImage>

      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h1 style={{color: '#fff', marginBottom: '25px'}}>The Process</h1>
          <Faq title="User Research" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee" open={true}>
          <h4 style={{color: '#a1ceb7', marginBottom: '15px'}}>Understanding users.</h4>
<p>Even with an intimate understanding of your business, industry and the audience you seek to target, truly standing in the shoes of your users can still be a challenge. So how do you make sure you have the necessary understanding of who you are building for, and what they need?</p>
<p>As part of our insight-led design process, we define both the information that’s required, and the best way to capture it. With a range of direct and indirect techniques at our disposal, we’ll select the ideal combination of research methods to give you the objective answers you need to make sound design decisions upfront.</p>

<h4 style={{color: '#a1ceb7', marginBottom: '15px'}}>Quality insight.</h4>
<p>When it comes to getting to know your users, spending time with them is essential. That’s why we use one-to-one interviews and group workshops to uncover the content, features and functionality that will serve, satisfy and delight.</p>
<p>As well as finding out what users think, though, this approach also reveals how they behave -  highlighting the subtle motivational triggers and persuasive ‘nudges’ that may otherwise have gone unnoticed. By really getting under their skin, you’ll be able to speak to your users at a much deeper, more personal level - delivering greater value for them while improving performance for you.</p>

<h4 style={{color: '#a1ceb7', marginBottom: '15px'}}>Quantifiable evidence.</h4>
<p>While talking to your users is an unbeatable way to gather rich, relevant feedback, for a truly holistic picture you also need to combine it with the facts and figures that matter.</p>
<p>We use analytics reviews and surveys to fill in any gaps in existing knowledge, and confirm assumptions about user segments, motivations, expectations, context of use, preferred devices, and more. Enabling a large volume of data to be gathered quickly, easily and cost-effectively, these indirect approaches also illustrate behavioural patterns and trends that may have been missed with a smaller data sample - so you can be sure your digital strategy addresses any potential challenges, along with valuable opportunities ripe for the taking.</p>
<p>Your are not your users. But with user research, it won’t feel that way.</p>
          </Faq>
          <Faq title="Usability Review" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>From websites and online portals through to multi-channel and e-commerce experiences, we can review any of your digital products and services to assess how well they adhere to good UX practice and key accessibility and usability standards.</p>
<p>Our expert usability reviews begin with our consultants taking a walk through your site or app, evaluating key tasks and user journeys along the way. We’ll then compile a bespoke, visual report that provides an invaluable insight into your digital product. This comprehensive document will:</p>
<ul>
  <li>Clearly identify issues that need to be resolved and, more importantly, outline the best ways to solve them.</li>
  <li>Pinpoint the areas that are working well, so that you can adopt best practice for future projects.</li>
  <li>Suggest new ideas for added functionality to improve usability even further.</li>
</ul>
<h4 style={{color: '#a1ceb7', marginBottom: '15px'}}>Planning or building something new?</h4>
<p>Let us run the rule over your wireframes, prototypes and design mockups as well. We can establish what’s working (and what’s not) early on, at the planning stage or before too much development work gets underway. We’ll also evaluate your competitors, identifying their strengths along with any weaknesses that you can exploit.</p>
          </Faq>
          <Faq title="Usability Testing" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          <p>When you’re committing long-term investment to the development of a new product, or to improving the performance of your current digital channels, you need the highest levels of confidence before you commit to any decisions.</p>
          <p>There are many ways to test usability and the method used is dependant on many project factors. From guerrilla testing to full laboratory testing, we can help.</p>
          </Faq>
          <Faq title="Information Architecture" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>The way you present content and pages plays a critical role in helping users achieve their online objectives - communicating subtle messages that signpost key user journeys, important information and next steps.</p>
<p>Whether you're starting from scratch, or looking to fine-tune what’s already there, we'll make sure your information architecture is aligned with your users' mental models, online behaviours and expectations. You’ll be able to serve their needs better - and see better results reflected in your business performance too.</p>
<p>It's all too easy to become too close to the inner workings of your site - to the point where improvements that could help users complete important tasks are overlooked. A fresh set of eyes can prove hugely insightful.</p>
<p>That's why our projects typically begin with a review of the information currently present. By objectively assessing how much there is, what format it comes in and how it's structured we can see:</p>
<ul>
  <li>Whether user requirements are being met.</li>
  <li>What information is duplicated or missing.</li>
  <li>Any gaps or missed opportunities.</li>
  <li>How well the navigation and taxonomy serves content.</li>
  <li>If the site search returns content appropriately.</li>
</ul>
<p>How, though, can you guarantee that your information architecture meets the needs of your users? You ask them, of course. We test proposed IA concepts with your users at an early stage, using exercises including card sorts and online tree testing. This ensures that the IA is reviewed in isolation, away from the distraction of page layouts, menus and visual design - increasing the accuracy of feedback to effectively validate decisions before we go too far down a particular route.</p>
<p>So if you want an information architecture that signposts the path to success, talk to us. We’ll make sure you set off in the right direction.</p>
          </Faq>
          <Faq title="UX Design" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>It’s our job to bring the user experience to life through truly innovative UX and visual designs that lift your digital presence to another level.</p>
<p>And we don’t wait until all the research and investigations have been done to start thinking about design - our consultants and designers are involved in your project from the start. They take part in briefing sessions, client meetings and scoping workshops to achieve a clear understanding of the vision that everyone is striving for.</p>

<h4 style={{color: '#a1ceb7', marginBottom: '15px'}}>Laying the groundwork.</h4>

<p>Rather than beginning with intricate interfaces at the outset, we build up our designs iteratively, starting with collaborative sketching workshops. Encouraging the rapid exploration of ideas, these sessions allow us to quickly see what works and what doesn’t before too much time is committed. And because your stakeholders are involved in the process, it’s also easier to secure buy-in from the people that matter.</p>
<p>Once we know the direction the designs are going to take, it’s time to get down to more detailed representations. We use interactive HTML prototypes, as these can be viewed and used in a realistic way - so you know what you’ll be getting at the end of your project. We can even try designs out on your users before development begins - they’re ideal for usability testing.</p>
<p>We then stamp your identity on the experience. Our visual designers will craft every element of your interface for maximum clarity and impact - helping users complete key tasks quickly and easily, while ensuring your brand messages are reinforced throughout.</p>
          </Faq>
          <Faq title="Visual Design" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          <p>An attractive site helps grab - and hold - people’s attention, encouraging them to explore further and complete key journeys and tasks. But simply brilliant designs shouldn’t stop there - they should also communicate important information quickly and clearly, while reinforcing your core brand messages at every turn.</p>
          </Faq>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="what-we-do" />
      </div>
  </Layout>
    )
  }
}
  