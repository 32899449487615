import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

const Header = styled.h2`
  margin: 0 .2em 1em .2em;
  padding: .8em 1em;
  position: relative;
  font-size: 1.25em;
  background: ${props => props.titleBackground};
  border-radius: 5px;
  color: ${props => props.titleColor};
`
const WhatWeDoUL = styled.ul`
  padding: 0; margin: 0 1em;
`;
const WhatWeDoArrow = styled.i`
  font-size: 16px;
  color: ${props => props.titleBackground} !important;
`;
const WhatWeDoLI = styled.li`
  list-style: none; margin-bottom: 15px; line-height: 20px;
`;
const WhatWeDoLink = styled(Link)`
  color: ${props => props.color};
  font-weight: 400;
  &:hover {
    color: ${props => props.color};
    filter: brightness(65%);
    text-decoration: none;
  }
`;


export default class extends Component {
  generateLi() {
    return this.props.links.map(o => {
      return (
        <WhatWeDoLI>
          <WhatWeDoLink {...this.props} to={o.link}><WhatWeDoArrow {...this.props} className="fas fa-arrow-right" /> {o.title}</WhatWeDoLink>
        </WhatWeDoLI>
      )
    })
  }
  render() {
    return (
      <div>
        <Header {...this.props}>{this.props.title}</Header>
        <WhatWeDoUL>
          {this.generateLi()}
        </WhatWeDoUL>
      </div>
    );
  }
}
