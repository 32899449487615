import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col, Collapse } from "reactstrap";

const Head = styled.div`
  text-align: left;
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  &:hover {
    filter: brightness(110%);
  }
`;
const Title = styled.div`
  color: ${props => props.titleColor};
  font-weight: 400;
  font-size: 2rem;
  padding: 20px 0 20px 60px;

  @media (max-width: 770px) {
    font-size: 1.5em;
  }
  @media (max-width: 480px) {
    font-size: 1em;
  }

`;
const Icon = styled.i`
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -16px;
  color: ${props => props.open ? props.iconColorOpen : props.iconColor};
`
const Content = styled.div`
  padding: 27px 38px;
  text-align: left;
  font-size: 18px;
`


export default class extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  componentWillMount() {
    if(this.props.open) {
      this.state.open = this.props.open
    }
  }

  click = () => {
    this.setState({
      open: !this.state.open
    })
  };

  getOpenIcon = () => this.state.open ? (
      <Icon iconColor={this.props.iconColor} iconColorOpen={this.props.iconColorOpen} open={this.state.open} className="fas fa-arrow-circle-up fa-2x" />
    ) : (
      <Icon iconColor={this.props.iconColor} iconColorOpen={this.props.iconColorOpen} open={this.state.open} className="fas fa-eye fa-2x" />
    )

  render() {
    return (
      <div style={{borderBottom: this.props.last ? 'none' : '1px solid #ccc'}}>
        <Head onClick={this.click}>
          {this.getOpenIcon()}
          <Title titleColor={this.state.open ? this.props.titleColorOpen : this.props.titleColor}>
            {this.props.title}
          </Title>
        </Head>
        <Collapse isOpen={this.state.open}>
          <Content style={{color: this.props.color}}>
            {this.props.children}
          </Content>
        </Collapse>
      </div>
    );
  }
}
